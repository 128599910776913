const Main = () => import('@/views/main.vue').then(m => m.default || m)

export default [
    {
        path: '',
        name: 'main',
        component: Main,
        props: true,
        meta: { footer: true },
    },
    {
        path: '*',
        redirect: '/',
    },
]