export const baseRemSize = 16

const getAgentData = (key) => {
    let parserKey = `,${key}:`;
    let retData = "";
    if (navigator.userAgent.search(parserKey) != -1) {
        retData = navigator.userAgent.substring(
            navigator.userAgent.indexOf(parserKey) + parserKey.length
        );
        retData = retData.substring(0, retData.indexOf(","));
    }
    return retData;
}